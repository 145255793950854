import { createSelector } from "@reduxjs/toolkit";
import { marketplaceApi } from "../marketplaceApi";
import {
  marketplaceAgentEntityAdapter,
  marketplaceAgentEntityAdapterInitialState
} from "../../../stores/adapters/marketplaceEntityAdapter";
import { RootState } from "../../../stores/store";
import { agentApi } from "../agentApi";

const selectMarketplaceAgentsResult =
  marketplaceApi.endpoints.getMarketplaceAgents.select();

const selectMarketplaceAgentsData = createSelector(
  selectMarketplaceAgentsResult,
  (marketplaceAgentsResult) => marketplaceAgentsResult.data
);

const selectAgentResults = agentApi.endpoints.getAgents.select();

const selectAgentData = createSelector(
  selectAgentResults,
  (agentResults) => agentResults.data
);

export const {
  selectAll: selectAllMarketplaceAgents,
  selectById: selectMarketplaceAgentById
} = marketplaceAgentEntityAdapter.getSelectors(
  (state: RootState) =>
    selectMarketplaceAgentsData(state) ??
    marketplaceAgentEntityAdapterInitialState
);

export const { selectAll: selectAllAgents, selectById: selectAgentById } =
  marketplaceAgentEntityAdapter.getSelectors(
    (state: RootState) =>
      selectAgentData(state) ?? marketplaceAgentEntityAdapterInitialState
  );
