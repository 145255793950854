import {
  Block,
  BlockNode,
  BlockType,
  DEFAULT_VERSION_VALUE,
  OpenAiTextModel,
  UtilBlockType,
  VERSION_2_VALUE,
  type AllBlockTypes,
  type DeepgramBlockData,
  type IterationExitBlockData,
  type IterationStartBlockData,
  type PerplexityBlockDataV1,
  type PerplexityBlockDataV2,
  type PromptBlockDataV1,
  type PromptBlockDataV2,
  type ScraperBlockDataV1,
  type ScraperBlockDataV2,
  type SerpBlockDataV1,
  type SerpBlockDataV2,
  type StructuredBlockDataV1,
  type StructuredBlockDataV2,
  type VersionedBlockConfig
} from "@toolflow/shared";
import { cloneDeep } from "lodash";
import {
  ConstantIcon,
  CrawlerIcon,
  DataExtractorIcon,
  ImageGenerationIcon,
  IterationEndIcon,
  IterationStartIcon,
  LogicIcon,
  SpeechToTextFillIcon,
  ToolIcon
} from "../../../../../../globalTheme/icons/icons";
import GoogleLogo from "../../../../../../globalTheme/icons/logos/GoogleLogo";
import OpenAILogo from "../../../../../../globalTheme/icons/logos/OpenAILogo";
import PerplexityLogo from "../../../../../../globalTheme/icons/logos/PerplexityLogo";
import {
  dallEPromptInitialState,
  deepgramTranscribePromptInitialState,
  iterationExitSettingsInitialState,
  iterationStartSettingsInitialState,
  promptInitialState,
  scraperSettingsInitialStateV1,
  scraperSettingsInitialStateV2,
  serpSettingsInitialStateV1,
  serpSettingsInitialStateV2
} from "../../context/initialStates";
import {
  perplexityInitialStateV1,
  perplexityInitialStateV2
} from "../../context/initialStates/perplexityInitialState";
import { structuredInitialState } from "../../context/initialStates/structuredInitialState";
import ConstantBlockInnerV1 from "../constantBlock/versions/1.0/ConstantBlockInnerV1";
import ConstantBlockInnerV2 from "../constantBlock/versions/2.0/ConstantBlockInnerV2";
import DeepgramBlockInner from "../deepgram/DeepgramBlockInner";
import TestDeepgram from "../deepgram/TestDeepgram";
import IterationExitBlockInner from "../IterationExitBlock/IterationExitBlockInner";
import IterationStartBlockInner from "../IterationStartBlock/IterationStartBlockInner";
import LogicInner from "../logic/LogicInner";
import PerplexityBlockInnerV1 from "../perplexityBlock/versions/1.0/PerplexityBlockInnerV1";
import TestPerplexityV1 from "../perplexityBlock/versions/1.0/TestPerplexityV1";
import PerplexityBlockInnerV2 from "../perplexityBlock/versions/2.0/PerplexityBlockInnerV2";
import TestPerplexityV2 from "../perplexityBlock/versions/2.0/TestPerplexityV2";
import PromptBlockInner from "../promptBlocks/versions/1.0/PromptBlockInner";
import TestPrompt from "../promptBlocks/versions/1.0/TestPrompt";
import PromptBlockInnerv2 from "../promptBlocks/versions/2.0/PromptBlockInnerv2";
import TestPromptV2 from "../promptBlocks/versions/2.0/TestPromptV2";
import ScraperBlockInnerV1 from "../scraperBlock/versions/v1/ScraperBlockInnerV1";
import TestScraperV1 from "../scraperBlock/versions/v1/TestScraperV1";
import ScraperBlockInnerV2 from "../scraperBlock/versions/v2/ScraperBlockInnerV2";
import TestScraperV2 from "../scraperBlock/versions/v2/TestScraperV2";
import SerpBlockInnerV1 from "../serpBlock/versions/1.0/SerpBlockInnerV1";
import TestSerpV1 from "../serpBlock/versions/1.0/TestSerpV1";
import SerpBlockInnerV2 from "../serpBlock/versions/2.0/SerpBlockInnerV2";
import TestSerpV2 from "../serpBlock/versions/2.0/TestSerpV2";
import StructuredBlockInnerV1 from "../structuredBlock/versions/1.0/StructuredBlockInnerV1";
import TestStructuredV1 from "../structuredBlock/versions/1.0/TestStructuredV1";
import StructuredBlockInnerV2 from "../structuredBlock/versions/2.0/StructuredBlockInnerV2";
import TestStructuredV2 from "../structuredBlock/versions/2.0/TestStructuredV2";
import ToolWithinToolBlockInner from "../toolWithinTool/ToolWithinToolBlockInner";
import { getBlockInputUpdaterFunction } from "./blockConfigFns/blockInputUpdater";
import { getBlockValidatorByVersion } from "./blockConfigFns/blockValidators";
import { getOutputConfigOptionsFn } from "./blockConfigFns/outputConfigOptions";
import getBlockConfigVersion from "./helpers/getBlockConfigVersion";
import { getLatestBlockVersion } from "./helpers/getLatestBlockVersion";

export const blockConfig: Record<Block, VersionedBlockConfig> = {
  [Block.logic]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: LogicIcon,
    drawerComponent: LogicInner,
    blockPaperLabel: "Logic",
    validate: getBlockValidatorByVersion(UtilBlockType.Logic),
    getOutputConfigOptions: getOutputConfigOptionsFn(),
    updateInput: getBlockInputUpdaterFunction(
      UtilBlockType.Logic,
      DEFAULT_VERSION_VALUE
    ),
    draggableItem: {
      type: BlockNode.LogicBlockNode,
      data: {
        label: "Logic",
        type: UtilBlockType.Logic,
        logicArray: []
      }
    }
  }),
  [Block.constant]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: ConstantIcon,
      drawerComponent: ConstantBlockInnerV1,
      blockPaperLabel: "Text composer",
      validate: getBlockValidatorByVersion(UtilBlockType.Constant),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        UtilBlockType.Constant,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.ConstantBlockNode,
        data: {
          label: "Text",
          type: UtilBlockType.Constant,
          constant: ""
        }
      }
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: ConstantIcon,
      drawerComponent: ConstantBlockInnerV2,
      blockPaperLabel: "Text composer",
      validate: getBlockValidatorByVersion(UtilBlockType.Constant),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        UtilBlockType.Constant,
        VERSION_2_VALUE
      ),
      draggableItem: {
        type: BlockNode.ConstantBlockNode,
        data: {
          label: "Text",
          type: UtilBlockType.Constant,
          constant: ""
        }
      }
    })
  },
  [Block.textGeneration]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      drawerComponent: PromptBlockInner,
      expandedComponent: TestPrompt,
      blockPaperLabel: "Text generator",
      icon: OpenAILogo,
      validate: getBlockValidatorByVersion(BlockType.ChatGPT),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.ChatGPT,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.PromptBlockNode,
        data: {
          type: BlockType.ChatGPT,
          label: "LLM output",
          ...promptInitialState
        } as PromptBlockDataV1
      }
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      drawerComponent: PromptBlockInnerv2,
      expandedComponent: TestPromptV2,
      blockPaperLabel: "Text generator",
      icon: OpenAILogo,
      validate: getBlockValidatorByVersion(BlockType.ChatGPT),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.ChatGPT,
        VERSION_2_VALUE
      ),
      draggableItem: {
        type: BlockNode.PromptBlockNode,
        data: {
          type: BlockType.ChatGPT,
          label: "LLM output",
          settings: {
            prompt: "",
            llmModel: OpenAiTextModel.GPT4o,
            temperature: 50
          }
        } as PromptBlockDataV2
      }
    })
  },
  [Block.imageGeneration]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    drawerComponent: PromptBlockInner,
    expandedComponent: TestPrompt,
    blockPaperLabel: "Image generator",
    icon: ImageGenerationIcon,
    validate: getBlockValidatorByVersion(BlockType.DallE),
    getOutputConfigOptions: getOutputConfigOptionsFn(),
    updateInput: getBlockInputUpdaterFunction(
      BlockType.DallE,
      DEFAULT_VERSION_VALUE
    ),
    draggableItem: {
      type: BlockNode.PromptBlockNode,
      data: {
        type: BlockType.DallE,
        label: "Image Output Name",
        ...dallEPromptInitialState
      } as PromptBlockDataV1
    }
  }),
  [Block.speechToText]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: SpeechToTextFillIcon,
    drawerComponent: DeepgramBlockInner,
    expandedComponent: TestDeepgram,
    blockPaperLabel: "Speech to text",
    validate: getBlockValidatorByVersion(BlockType.Deepgram),
    getOutputConfigOptions: getOutputConfigOptionsFn(),
    updateInput: getBlockInputUpdaterFunction(
      BlockType.Deepgram,
      DEFAULT_VERSION_VALUE
    ),
    draggableItem: {
      type: BlockNode.DeepgramBlockNode,
      data: {
        ...deepgramTranscribePromptInitialState,
        type: BlockType.Deepgram,
        label: "Transcript"
      } as DeepgramBlockData
    }
  }),
  [Block.scraper]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: CrawlerIcon,
      expandedComponent: TestScraperV1,
      drawerComponent: ScraperBlockInnerV1,
      blockPaperLabel: "Web scraper",
      validate: getBlockValidatorByVersion(BlockType.Scraper),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.Scraper,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.ScraperBlockNode,
        data: {
          ...scraperSettingsInitialStateV1,
          type: BlockType.Scraper,
          label: "Website content"
        } as ScraperBlockDataV1
      }
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: CrawlerIcon,
      expandedComponent: TestScraperV2,
      drawerComponent: ScraperBlockInnerV2,
      blockPaperLabel: "Web scraper",
      validate: getBlockValidatorByVersion(BlockType.Scraper, VERSION_2_VALUE),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.Scraper,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.ScraperBlockNode,
        data: {
          ...scraperSettingsInitialStateV2,
          type: BlockType.Scraper,
          label: "Website content"
        } as ScraperBlockDataV2
      }
    })
  },
  [Block.serp]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: GoogleLogo,
      drawerComponent: SerpBlockInnerV1,
      expandedComponent: TestSerpV1,
      blockPaperLabel: "Google search",
      validate: getBlockValidatorByVersion(BlockType.SERP),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.SERP,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.SerpBlockNode,
        data: {
          ...serpSettingsInitialStateV1,
          type: BlockType.SERP,
          label: "Google Search Results"
        } as SerpBlockDataV1
      }
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: GoogleLogo,
      drawerComponent: SerpBlockInnerV2,
      expandedComponent: TestSerpV2,
      blockPaperLabel: "Google search",
      validate: getBlockValidatorByVersion(BlockType.SERP),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.SERP,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.SerpBlockNode,
        data: {
          ...serpSettingsInitialStateV2,
          type: BlockType.SERP,
          label: "Google Search Results"
        } as SerpBlockDataV2
      }
    })
  },
  [Block.structured]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: DataExtractorIcon,
      expandedComponent: TestStructuredV1,
      drawerComponent: StructuredBlockInnerV1,
      blockPaperLabel: "Data extractor",
      validate: getBlockValidatorByVersion(BlockType.Structured),
      getOutputConfigOptions: getOutputConfigOptionsFn(BlockType.Structured),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.Structured,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.StructuredBlockNode,
        data: {
          ...cloneDeep(structuredInitialState),
          type: BlockType.Structured,
          label: "Data extractor"
        } as StructuredBlockDataV1
      }
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: DataExtractorIcon,
      blockPaperLabel: "Data extractor",
      expandedComponent: TestStructuredV2,
      drawerComponent: StructuredBlockInnerV2,
      validate: getBlockValidatorByVersion(BlockType.Structured),
      getOutputConfigOptions: getOutputConfigOptionsFn(BlockType.Structured),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.Structured,
        VERSION_2_VALUE
      ),
      draggableItem: {
        type: BlockNode.StructuredBlockNode,
        data: {
          ...cloneDeep(structuredInitialState),
          type: BlockType.Structured,
          label: "Data extractor"
        } as StructuredBlockDataV2
      }
    })
  },
  [Block.perplexity]: {
    ...getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
      icon: PerplexityLogo,
      drawerComponent: PerplexityBlockInnerV1,
      expandedComponent: TestPerplexityV1,
      blockPaperLabel: "Perplexity",
      validate: getBlockValidatorByVersion(BlockType.Perplexity),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.Perplexity,
        DEFAULT_VERSION_VALUE
      ),
      draggableItem: {
        type: BlockNode.PerplexityBlockNode,
        data: {
          ...perplexityInitialStateV1,
          type: BlockType.Perplexity,
          label: "Perplexity research"
        } as PerplexityBlockDataV1
      }
    }),
    ...getBlockConfigVersion(VERSION_2_VALUE, {
      icon: PerplexityLogo,
      drawerComponent: PerplexityBlockInnerV2,
      expandedComponent: TestPerplexityV2,
      blockPaperLabel: "Perplexity",
      validate: getBlockValidatorByVersion(BlockType.Perplexity),
      getOutputConfigOptions: getOutputConfigOptionsFn(),
      updateInput: getBlockInputUpdaterFunction(
        BlockType.Perplexity,
        VERSION_2_VALUE
      ),
      draggableItem: {
        type: BlockNode.PerplexityBlockNode,
        data: {
          ...perplexityInitialStateV2,
          type: BlockType.Perplexity,
          label: "Perplexity research"
        } as PerplexityBlockDataV2
      }
    })
  },
  [Block.embedded]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: ToolIcon,
    drawerComponent: ToolWithinToolBlockInner,
    blockPaperLabel: "Embedded Tool",
    validate: getBlockValidatorByVersion(UtilBlockType.ToolWithinTool),
    getOutputConfigOptions: getOutputConfigOptionsFn(),
    updateInput: getBlockInputUpdaterFunction(
      UtilBlockType.ToolWithinTool,
      DEFAULT_VERSION_VALUE
    ),
    draggableItem: {
      type: BlockNode.ToolWithinToolBlockNode,
      data: {
        label: "Embedded Tool",
        type: UtilBlockType.ToolWithinTool,
        tool: null,
        inputMap: null
      }
    }
  }),
  [Block.iterationStart]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: IterationStartIcon,
    drawerComponent: IterationStartBlockInner,
    blockPaperLabel: "Start iteration",
    validate: getBlockValidatorByVersion(BlockType.IterationStart),
    getOutputConfigOptions: getOutputConfigOptionsFn(),
    updateInput: getBlockInputUpdaterFunction(
      BlockType.IterationStart,
      DEFAULT_VERSION_VALUE
    ),
    draggableItem: {
      type: BlockNode.IterationStartBlockNode,
      data: {
        ...iterationStartSettingsInitialState,
        type: BlockType.IterationStart,
        label: "Start iteration"
      } as IterationStartBlockData
    }
  }),
  [Block.iterationExit]: getBlockConfigVersion(DEFAULT_VERSION_VALUE, {
    icon: IterationEndIcon,
    drawerComponent: IterationExitBlockInner,
    getOutputConfigOptions: getOutputConfigOptionsFn(),
    validate: getBlockValidatorByVersion(BlockType.IterationExit),
    blockPaperLabel: "Exit iteration",
    draggableItem: {
      type: BlockNode.IterationExitBlockNode,
      data: {
        ...iterationExitSettingsInitialState,
        type: BlockType.IterationExit,
        label: "Exit iteration"
      } as IterationExitBlockData
    }
  })
};

export function getBlockConfig(
  block: Block,
  version = DEFAULT_VERSION_VALUE
): (typeof blockConfig)[Block][string] {
  const blockVersions = blockConfig[block];

  return blockVersions[version] || blockVersions[DEFAULT_VERSION_VALUE];
}

export function getLatestBlockConfig(type: Block) {
  return getBlockConfig(type, getLatestBlockVersion(type));
}

function getBlockByNodeType(block: AllBlockTypes) {
  const BlockMap: Record<AllBlockTypes, Block> = {
    [BlockType.ChatGPT]: Block.textGeneration,
    [BlockType.Deepgram]: Block.speechToText,
    [BlockType.Scraper]: Block.scraper,
    [BlockType.SERP]: Block.serp,
    [BlockType.Structured]: Block.structured,
    [BlockType.Perplexity]: Block.perplexity,
    [BlockType.DallE]: Block.imageGeneration,
    [BlockType.IterationStart]: Block.iterationStart,
    [BlockType.IterationExit]: Block.iterationExit,
    [BlockType.Anthropic]: Block.textGeneration,
    [UtilBlockType.ToolWithinTool]: Block.embedded,
    [UtilBlockType.Logic]: Block.logic,
    [UtilBlockType.Constant]: Block.constant
  };

  return BlockMap[block];
}

export function getLatestBlockConfigByNodeType(blockType: AllBlockTypes) {
  const type = getBlockByNodeType(blockType);
  return getLatestBlockConfig(type);
}
