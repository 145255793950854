import { useSelector } from "react-redux";
import { useGetCurrentUserProfileId } from "../user/hooks/useGetCurrentUserProfileId";
import { useGetAgentsQuery } from "../../ToolflowAPI/rtkRoutes/agentApi";
import { selectAllAgents } from "../../ToolflowAPI/rtkRoutes/selectors/agentSelectors";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { EFeatureFlags } from "@toolflow/shared";

export const useGetCurrentUserAgents = () => {
  const addAgentEnabled = useFeatureFlagEnabled(EFeatureFlags.AGENT_BUILDER);
  const profileId = useGetCurrentUserProfileId();
  const { isFetching, isLoading } = useGetAgentsQuery(undefined, {
    skip: !profileId || !addAgentEnabled
  }); // we need this to stay subscribed to the query
  const agents = useSelector(selectAllAgents);

  return {
    agents,
    loading: isLoading || isFetching
  };
};
