import React, { useMemo, useState } from "react";
import { type UserInputDictType } from "@toolflow/shared";
import { Divider } from "@mui/material";
import AccordionWrapper from "../../../../../../../utilities/components/accordions/AccordionWrapper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { a11yProps } from "../../../../../../../utilities/components/tabs/functions/a11yProps";
import TabPanel from "../../../../../../../utilities/components/tabs/TabPanel";
import { generateText, type JSONContent } from "@tiptap/core";
import TestInputTab from "./TestInputTab";
import TestPromptPreviewTab from "./TestPromptPreviewTab";
import { textInputContainerExtensionsConfig } from "../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import { customExtensions } from "../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/customExtensions";
import { getExtensionsFromConfig } from "../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/getExtensionsFromConfig";

enum TestTab {
  Inputs = "collect-inputs",
  Preview = "prompt-preview"
}

type TestTabDict = {
  [key in TestTab]: {
    value: number;
    component: React.ReactNode;
    label: string;
  };
};

function TestFieldsV2({
  uI,
  setUI,
  filledOutPrompt,
  promptHelperLabel,
  label
}: {
  uI: UserInputDictType;
  setUI: React.Dispatch<React.SetStateAction<UserInputDictType>>;
  filledOutPrompt: string;
  promptHelperLabel?: string;
  label: string;
}) {
  const extensions = getExtensionsFromConfig(
    textInputContainerExtensionsConfig,
    customExtensions
  );

  // we need to transofr the JSONContent to a string
  // this doesn't work when keeping chips as chips (for when we save testing data), but its a temporary solution
  const handleUpdate = (field: string, e: string | JSONContent) => {
    setUI({
      ...uI,
      [field]: typeof e === "string" ? e : generateText(e, extensions)
    });
  };

  const fields = useMemo(() => Object.keys(uI), [uI]);

  const tabDict: TestTabDict = useMemo(() => {
    return {
      [TestTab.Inputs]: {
        value: 0,
        component: (
          <TestInputTab fields={fields} uI={uI} handleUpdate={handleUpdate} />
        ),
        label: "Test Inputs"
      },
      [TestTab.Preview]: {
        value: 1,
        component: (
          <TestPromptPreviewTab
            filledOutPrompt={filledOutPrompt}
            helperLabel={promptHelperLabel}
          />
        ),
        label: "Prompt Preview"
      }
    };
  }, [fields, uI, filledOutPrompt]);

  const [value, setValue] = useState(TestTab.Inputs);

  const handleChange = (event: React.SyntheticEvent, newValue: TestTab) => {
    setValue(newValue);
  };

  return (
    <AccordionWrapper title={label} elevation={0} startsExpanded>
      <div>
        <Tabs value={value} onChange={handleChange}>
          {Object.entries(tabDict).map(([tabName, tabDetails]) => (
            <Tab
              label={tabDetails.label}
              key={tabName}
              value={tabName}
              {...a11yProps(tabDetails.value)}
              className="m-l-12px"
            />
          ))}
        </Tabs>
        <Divider />
        {Object.entries(tabDict).map(([tabName, tabDetails]) => (
          <TabPanel value={value} key={tabName} index={tabName}>
            <div className="m-t-24px">{tabDetails.component}</div>
          </TabPanel>
        ))}
      </div>
    </AccordionWrapper>
  );
}

export default TestFieldsV2;
