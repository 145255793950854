import {
  FieldType,
  type ConstantBlockDataV2,
  type FieldConfig
} from "@toolflow/shared";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import useUpdateField from "../../../../useUpdateField";

export default function ConstantBlockInnerV2({
  id,
  data
}: {
  id: string;
  data: ConstantBlockDataV2;
}) {
  const updateField = useUpdateField(id);
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields: [
      {
        label: "",
        type: FieldType.PromptInputV2,
        config: {
          fieldKey: "constant",
          placeholder: `Type "/" or click "+" for dynamic inputs`,
          autofocus: true,
          className: "min-h-246px",
          chipsToUse
        }
      }
    ]
  };

  return (
    <div className="flex flex-column m-t-16px">
      <FieldsByType configs={configs} />
    </div>
  );
}
