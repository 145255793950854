import { type StructuredBlockPropsV2 } from "@toolflow/shared";
import { useMemo } from "react";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import useFields from "../../../hooks/useFields";
import TestFieldsV2 from "../../../promptBlocks/testFields/TestFieldsV2";
import usePromptFiller from "../../../promptBlocks/usePromptFiller";
import TestStructuredBase from "../../helpers/TestStructuredBase";
import useGetChips from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";

export default function TestStructuredV2({
  data,
  id
}: Omit<StructuredBlockPropsV2, "selected">) {
  const getChipsList = useGetChips();
  const input = data.settings.input;

  const fieldsToTest = useMemo(() => getChipsList(input), [input]);
  const { userInput, setUserInput, fields } = useFields(fieldsToTest);

  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );

  const filledOutPrompt = useMemo(
    () => insertUserInputIntoPrompt(input, userInput),
    [userInput, input, insertUserInputIntoPrompt]
  );

  return (
    <TestStructuredBase
      data={data}
      userInput={userInput}
      id={id}
      fields={fields}
      testFields={
        <TestFieldsV2
          uI={userInput}
          setUI={setUserInput}
          filledOutPrompt={filledOutPrompt}
          label={"Data Extractor Prompt"}
          promptHelperLabel="Complete prompt sent to AI, with all instructions and inputs."
        />
      }
    />
  );
}
