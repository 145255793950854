import { FieldType } from "@toolflow/shared";
import React from "react";
import DynamicInputField from "./fields/dynamicFields/dynamicInputField/DynamicInputField";
import { DynamicListField } from "./fields/dynamicFields/dynamicListField/DynamicListField";
import DynamicSelectField from "./fields/dynamicFields/dynamicSelectField/DynamicSelectField";
import DynamicSliderField from "./fields/dynamicFields/dynamicSliderField/DynamicSliderField";
import DynamicSwitchField from "./fields/dynamicFields/dynamicSwitchField/DynamicSwitchField";
import JsonSchemaInputField from "./fields/jsonSchemaInputField/JsonSchemaInputField";
import NewSelectField from "./fields/NewSelectField";
import PromptInputWrapper from "./fields/PromptInputWrapper";
import { SelectFieldV1 } from "./fields/selectField";
import SelectToolsField from "./fields/selectTools/SelectToolsField";
import InputTypeSelectWrapper from "./fields/selectWithInput/InputTypeSelectWrapper";
import SliderField from "./fields/SliderField";
import TextInputContainer from "./fields/textInputContainer/TextInputContainer";

// Combined object for components and prop getters
export const fieldTypeComponents: Record<FieldType, React.ElementType> = {
  [FieldType.PromptInput]: PromptInputWrapper,
  [FieldType.PromptInputV2]: TextInputContainer,
  [FieldType.SelectInputV1]: SelectFieldV1,
  [FieldType.CustomSelectInput]: InputTypeSelectWrapper,
  [FieldType.JsonSchemaInput]: JsonSchemaInputField,
  [FieldType.Slider]: SliderField,
  [FieldType.NewSelectInput]: NewSelectField,
  [FieldType.DynamicListField]: DynamicListField,
  [FieldType.DynamicSwitchField]: DynamicSwitchField,
  [FieldType.DynamicSelectField]: DynamicSelectField,
  [FieldType.DynamicInputField]: DynamicInputField,
  [FieldType.DynamicSliderField]: DynamicSliderField,
  [FieldType.SelectTools]: SelectToolsField,
  [FieldType.Group]: () => <></>
};
