import { type PromptBlockPropsV2 } from "@toolflow/shared";
import TestPromptBase from "../helpers/TestPromptBase";
import { useMemo } from "react";
import useFields from "../../../hooks/useFields";
import useGetChips from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";
import usePromptFiller from "../../usePromptFiller";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import TestFieldsV2 from "../../testFields/TestFieldsV2";

const TestPrompt = ({ data, id }: Omit<PromptBlockPropsV2, "selected">) => {
  const getChipsList = useGetChips();
  const prompt = data.settings.prompt;

  const fieldsToTest = useMemo(() => getChipsList(prompt), [prompt]);
  const { userInput, setUserInput, fields } = useFields(fieldsToTest);

  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );
  const filledOutPrompt = useMemo(
    () => insertUserInputIntoPrompt(prompt, userInput),
    [userInput, prompt, insertUserInputIntoPrompt]
  );

  return (
    <TestPromptBase
      data={data}
      userInput={userInput}
      id={id}
      fields={fields}
      testFields={
        <TestFieldsV2
          uI={userInput}
          setUI={setUserInput}
          filledOutPrompt={filledOutPrompt}
          label={"LLM Prompt"}
          promptHelperLabel="Complete prompt sent to AI, with all instructions and inputs."
        />
      }
    />
  );
};

export default TestPrompt;
