import { TextField } from "@mui/material";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import type { UserInputDictType } from "@toolflow/shared";

const TestSerpFields = ({
  fields,
  uI,
  setUI
}: {
  fields: string[];
  uI: UserInputDictType;
  setUI: React.Dispatch<React.SetStateAction<UserInputDictType>>;
}) => {
  const handleUpdate = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newUserInput = { ...uI, [field]: e.target.value };
    setUI(newUserInput);
  };

  return (
    <AccordionWrapper title="Test" elevation={0} startsExpanded>
      {fields.map((field, idx) => (
        <TextField
          name={field}
          key={idx}
          label={field}
          value={uI[field]}
          onChange={(e) => handleUpdate(field, e)}
          multiline
          fullWidth
          margin="normal"
          maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        />
      ))}
    </AccordionWrapper>
  );
};

export default TestSerpFields;
