import { useBlocker } from "react-router-dom";
import type { TAgentBuilderForm } from "@toolflow/shared";
import useFormIsDirty from "../../../../../utilities/hooks/useIsDirty";

const useAgentBlocker = () => {
  const { isDirty } = useFormIsDirty<TAgentBuilderForm>();

  const blocker = useBlocker(isDirty);

  return blocker;
};

export default useAgentBlocker;
