import { JSONContent } from "@tiptap/core";
import { deepCopy } from "./deepCopy";
import { TContent } from "../tiptap";
import { MapperObject } from "../../types";

export function getStringsBetweenBraces(str: string) {
  const results: number[][] = [];
  let curIdx = 0;
  for (let i = 0; i < str.length - 1; i++) {
    if (str[i] === "{" && str[i + 1] === "{") {
      results[curIdx] = [i + 2];
    } else if (str[i] === "}" && str[i + 1] === "}") {
      results[curIdx].push(i);
      curIdx++;
    }
  }
  return results.map((indices) => str.slice(indices[0], indices[1]));
}

export function updateNodeName({
  stringToUpdate,
  deleteBrackets,
  oldName,
  newName = ""
}: {
  stringToUpdate: string;
  deleteBrackets?: boolean;
  oldName: string;
  newName?: string;
}) {
  const splitted = stringToUpdate.split(".");
  const blockName = splitted.at(0);

  if (deleteBrackets && [stringToUpdate, blockName].includes(oldName)) {
    return stringToUpdate.replaceAll(stringToUpdate, "");
  } else if (stringToUpdate === oldName) {
    return newName;
  } else if (blockName === oldName) {
    return stringToUpdate.replaceAll(oldName, newName);
  }

  return stringToUpdate;
}

export function updateStringWithNewLabel({
  stringToUpdate,
  deleteBrackets,
  deletedToolInputFieldName,
  newName = ""
}: {
  stringToUpdate: string;
  deleteBrackets?: boolean;
  deletedToolInputFieldName: string;
  newName?: string;
}) {
  const UIKeys = getStringsBetweenBraces(stringToUpdate);
  // Brackets can contain Block/Input name or a nested path with Block/Input name.
  // So, we need to check for both cases.

  return UIKeys.reduce((prompt, UIkey) => {
    const splitted = UIkey.split(".");
    const key = splitted.at(0);

    if (deleteBrackets) {
      if ([UIkey, key].includes(deletedToolInputFieldName)) {
        prompt = prompt.replaceAll(`{{${UIkey}}}`, "");
      }
    } else if (UIkey === deletedToolInputFieldName) {
      prompt = prompt.replaceAll(`{{${UIkey}}}`, `{{${newName}}}`);
    } else if (key === deletedToolInputFieldName) {
      splitted[0] = newName;
      const joined = splitted.join(".");
      prompt = prompt.replaceAll(`{{${UIkey}}}`, `{{${joined}}}`);
    }

    return prompt;
  }, stringToUpdate);
}

/**
 * Finds and updates deeply nested block references in TipTap JSONContent
 *
 * @returns JSONContent
 */
export function updateDeepContentName(
  item: TContent,
  oldString: string,
  newString?: string
): JSONContent {
  const copy: JSONContent = deepCopy(item as JSONContent);
  return {
    ...copy,
    content: copy.content?.map((pc) => {
      if (pc.attrs?.name === oldString) {
        pc.attrs.name = newString;
        pc.attrs.textValue = newString;
      }
      if (pc.content?.length) {
        return updateDeepContentName(pc, oldString, newString);
      }
      return pc;
    })
  } as JSONContent;
}

export function returnNewInputMap(
  inputMap: MapperObject,
  oldName: string,
  newName: string
) {
  if (oldName === "") return inputMap;
  const newInputMap = { ...inputMap };
  Object.entries(newInputMap).map(([k, v]) => {
    if (v === oldName) {
      newInputMap[k] = newName;
    }
  });
  return newInputMap;
}
