import React from "react";
import ToolflowLogo from "../../../globalTheme/icons/logos/toolflowLogo/ToolflowLogo";
import { Typography, Button } from "@mui/material";
import LoggedOutLinks from "./LoggedOutLinks";
import { useLoginBackToPage } from "../../auth/hooks/useLoginBackToPage";

export const LoggedOutGroup = () => {
  const { loginBackToPage } = useLoginBackToPage();
  return (
    <>
      <ToolflowLogo className="m-b-16px" />
      <Typography variant="h6" className="m-v-16px">
        The AI-powered workspace
      </Typography>
      <Button variant="contained" size="large" onClick={loginBackToPage}>
        Login
      </Button>
      <Typography variant="caption" className="m-t-16px">
        Not a user?
      </Typography>
      <Button variant="text" size="small" onClick={loginBackToPage}>
        Sign up
      </Button>
    </>
  );
};

function LoggedOut() {
  return (
    <div className="w-100-percent justify-center align-i-center flex h-100vh flex-column">
      <LoggedOutGroup />
      <LoggedOutLinks />
    </div>
  );
}

export default LoggedOut;
