import { Stack } from "@mui/material";
import {
  BlockNode,
  type SerpBlockDataV2,
  type SerpBlockPropsV2
} from "@toolflow/shared";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../../../common/TestToolRun";
import TestOutput from "../../../components/testWrapper/TestOutput";
import useTest from "../../../hooks/useTest";
import useTestInputFromFieldsByType from "../../../hooks/useTestInputFromFieldsByType";
import TestSerpFields from "./TestSerpFields";
import useSerpFieldsConfig from "./useSerpFieldsConfig";

function TestSerpV2({ data, id }: Omit<SerpBlockPropsV2, "selected">) {
  const fieldsByType = useSerpFieldsConfig();
  const { fields, userInput, setUserInput } = useTestInputFromFieldsByType(
    fieldsByType,
    data,
    [data.settings]
  );

  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<SerpBlockDataV2>({
      data,
      id,
      blockType: BlockNode.SerpBlockNode,
      fields,
      userInput
    });

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestSerpFields fields={fields} uI={userInput} setUI={setUserInput} />
          <TestOutput output={output} label={label} />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}

export default TestSerpV2;
