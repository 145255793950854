import { FormControl } from "@mui/material";
import {
  DynamicFieldType,
  type DynamicSwitchFieldConfig,
  type DynamicSwitchFieldType
} from "@toolflow/shared";
import { Switch } from "../../../../../builderDrawer/components/drawerContent/outputs/outputFields/Switch";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import DynamicFieldWrapper from "../../dynamicFieldWrapper/DynamicFieldWrapper";

export default function DynamicSwitchField() {
  const { field, id, data, updateField } =
    useFieldsByTypeContext<DynamicSwitchFieldType>();
  const { config, label, subtitle } = field;
  const {
    fieldKey,
    typeKey,
    switchLabel,
    defaultValue = false,
    filterAvailableFields
  } = config as DynamicSwitchFieldConfig;
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });

  const dynamicFieldValue = getFieldValue(typeKey, "");
  const fieldValue = getFieldValue(fieldKey, defaultValue);

  return (
    <FormControl margin="normal" fullWidth>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={config}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        onTypeChangeCallback={(value) => {
          if (value === DynamicFieldType.Dynamic) {
            updateField("", fieldKey);
          } else {
            updateField(defaultValue, fieldKey);
          }
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <Switch
            checked={fieldValue}
            label={switchLabel || "Enable this option"}
            onChange={(value) => updateField(value, fieldKey)}
          />
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
