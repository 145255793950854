import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  TextField
} from "@mui/material";
import { type StructuredBlockSettingsV1 } from "@toolflow/shared";
import { ArrowDownSmallIcon } from "../../../../../../../../globalTheme/icons/icons";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../../../globalTheme/muiUtils/appTheme";

export default function StructuredBlockTestInput({
  settings
}: {
  settings: StructuredBlockSettingsV1;
}) {
  return (
    <Accordion elevation={0} defaultExpanded>
      <AccordionSummary
        expandIcon={<ArrowDownSmallIcon size={16} color="#12172966" />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Modify input
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <TextField
            margin="none"
            value={settings.input}
            multiline
            fullWidth
            disabled
            maxRows={MAX_ROWS_LARGE_TEXTFIELD}
            helperText={`${settings.input?.length} characters`}
          />
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
}
