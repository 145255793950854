import {
  BlockNode,
  type ScraperBlockPropsV1,
  type ScraperBlockDataV1
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import TestToolRun from "../../../../common/TestToolRun";
import { TEXTFIELD } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestScraperFields from "./TestScraperFields";
import TestBlockOutput from "../../../components/TestBlockOutput";
import useTest from "../../../hooks/useTest";
import useFields from "../../../hooks/useFields";

function TestScraperV1({ data, id }: Omit<ScraperBlockPropsV1, "selected">) {
  const { settings } = data;

  const { userInput, setUserInput, fields } = useFields([]);

  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<ScraperBlockDataV1>({
      data,
      id,
      customField: {
        name: data.settings.urlFieldInputKey,
        id: uuidv4(),
        type: TEXTFIELD
      },
      blockType: BlockNode.ScraperBlockNode,
      fields,
      userInput
    });

  return (
    <DrawerBottom
      mainContent={
        <>
          <TestScraperFields
            id={id}
            uI={userInput}
            setUI={setUserInput}
            urlFieldInputKey={settings.urlFieldInputKey}
          />
          <TestBlockOutput output={output} label={label} />
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          disabled={!settings.urlFieldInputKey}
          loading={loading}
        />
      }
    />
  );
}

export default TestScraperV1;
