import { type ValidatedInput } from "@toolflow/shared";

export function filterAvailableFieldsForArray(fields: ValidatedInput[]) {
  return fields.filter((field) => {
    return (
      (field.config?.valueType === "array" || field.type === "tagsInput") &&
      !field.config?.nestedOutputKey?.includes("[]")
    );
  });
}
