import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { type JSONValue } from "@toolflow/shared";
import {
  isArray,
  isBoolean,
  isFunction,
  isNull,
  isNumber,
  isObject,
  isString
} from "lodash";
import { Fragment, ReactNode, useState } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../globalTheme/icons/icons";
import { isHandledChatStackError } from "../../../typeCheckers";
import OutputLoadingWrapper from "../../../../../../../utilities/components/loading/HideWhenLoadingWrapper";

const isSingleLineValue = (val: $TSAllowedAny) =>
  isString(val) || isNumber(val) || isBoolean(val);

const isArrayOfSingleValues = (val: $TSAllowedAny) =>
  isArray(val) && val.every((item) => isSingleLineValue(item));

const isObjectType = (val: $TSAllowedAny) =>
  isObject(val) && !isArray(val) && !isNull(val) && !isFunction(val);

const isArrayOfObjects = (val: $TSAllowedAny) =>
  isArray(val) && val.every((item) => isObjectType(item));

export function StructuredOutputAccordion({
  name,
  transparentBg,
  children
}: {
  name: string;
  transparentBg?: boolean;
  children: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <ButtonBase
        disableRipple
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingBlock: 1,
          paddingInline: 0
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Typography variant="h9">{name}</Typography>
        <Box sx={{ rotate: isOpen ? `180deg` : 0 }}>
          <ArrowDownSmallIcon size={16} color="#12172966" />
        </Box>
      </ButtonBase>

      <Box
        sx={{
          display: "grid",
          gridTemplateRows: isOpen ? "1fr" : "0fr",
          transition: "grid-template-rows 300ms ease"
        }}
      >
        <Box sx={{ overflow: "hidden" }}>
          <Box
            sx={{
              borderRadius: 2,
              paddingInline: 1.5,
              paddingBlock: 1.3,
              ...(transparentBg
                ? { border: "1px solid rgba(228, 223, 212, 0.50)" }
                : { backgroundColor: "#FAF8F7" })
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export function StructuredSingleValue({
  name,
  value
}: {
  name: string;
  value: string | number | boolean;
}) {
  return (
    <StructuredOutputAccordion name={name}>
      <Typography>{`${value}`}</Typography>
    </StructuredOutputAccordion>
  );
}

export function StructuredListOfSingleValues({
  name,
  value
}: {
  name: string;
  value: (string | number | boolean)[];
}) {
  return (
    <StructuredOutputAccordion name={name}>
      <Stack spacing={1} sx={{ padding: 1 }}>
        {value.map((item, index) => {
          return <Typography key={index}>{`${index + 1}. ${item}`}</Typography>;
        })}
      </Stack>
    </StructuredOutputAccordion>
  );
}

export function StructuredObject({
  name,
  value,
  transparentBg
}: {
  name: string;
  value: JSONValue;
  transparentBg?: boolean;
}) {
  return (
    <StructuredOutputAccordion name={name} transparentBg={transparentBg}>
      {Object.entries(value).map(([key, val], index) => {
        return (
          <Fragment key={index}>
            {isHandledChatStackError(value) ? (
              <StructuredSingleValue name={key} value={value.data.message} />
            ) : isSingleLineValue(val) ? (
              <StructuredSingleValue name={key} value={val} />
            ) : isArrayOfSingleValues(val) ? (
              <StructuredListOfSingleValues name={key} value={val} />
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
    </StructuredOutputAccordion>
  );
}

export function StructuredListOfObjects({
  name,
  value
}: {
  name: string;
  value: JSONValue[];
}) {
  return (
    <StructuredOutputAccordion name={name} transparentBg>
      <Stack spacing={1} sx={{ padding: 1 }}>
        {value.map((item, index) => {
          return (
            <Fragment key={index}>
              <StructuredObject
                name={`${index + 1}`}
                value={item}
                transparentBg
              />
            </Fragment>
          );
        })}
      </Stack>
    </StructuredOutputAccordion>
  );
}

export default function StructuredOutput({
  value,
  showLoading
}: {
  value: JSONValue;
  showLoading?: boolean;
}) {
  if (!isObject(value)) return null;

  return (
    <OutputLoadingWrapper showLoading={showLoading}>
      <Stack spacing={1}>
        {Object.entries(value).map(([key, val], index) => {
          return (
            <Fragment key={index}>
              {isHandledChatStackError(val) ? (
                <StructuredSingleValue name={key} value={val.data.message} />
              ) : isSingleLineValue(val) ? (
                <StructuredSingleValue name={key} value={val} />
              ) : isArrayOfSingleValues(val) ? (
                <StructuredListOfSingleValues name={key} value={val} />
              ) : isArrayOfObjects(val) ? (
                <StructuredListOfObjects name={key} value={val} />
              ) : isObjectType(val) ? (
                <StructuredObject name={key} value={val} />
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}
      </Stack>
    </OutputLoadingWrapper>
  );
}
