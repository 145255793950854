export type DefaultBlockType = "default";

export enum Block {
  textGeneration = "textGeneration",
  embedded = "embedded",
  logic = "logic",
  constant = "constant",
  speechToText = "speechToText",
  scraper = "scraper",
  serp = "serp",
  structured = "structured",
  perplexity = "perplexity",
  imageGeneration = "imageGeneration",
  iterationStart = "iterationStart",
  iterationExit = "iterationExit"
}

export enum BlockType {
  DallE = "Dall-E2", // this is a misnomer, this is for all Dall-E types, we may want to migrate the names to "Dall-E" in the future
  ChatGPT = "ChatGPT",
  Anthropic = "Anthropic",
  Structured = "Structured",
  Perplexity = "Perplexity",
  SERP = "serp",
  Deepgram = "DeepgramTranscribe",
  Scraper = "WebsiteContentCrawler",
  IterationStart = "IterationStart",
  IterationExit = "IterationExit"
}

export enum UtilBlockType {
  Constant = "constant",
  Logic = "logic",
  ToolWithinTool = "toolWithinTool"
}

export type AllBlockTypes = BlockType | UtilBlockType;

export enum BlockNode {
  PromptBlockNode = "promptBlockNode",
  ToolWithinToolBlockNode = "toolWithinToolBlockNode",
  LogicBlockNode = "logicBlockNode",
  ConstantBlockNode = "constantBlockNode",
  DeepgramBlockNode = "deepgramBlockNode",
  ScraperBlockNode = "scraperBlockNode",
  SerpBlockNode = "serpBlockNode",
  StructuredBlockNode = "structuredBlockNode",
  PerplexityBlockNode = "perplexityBlockNode",
  IterationStartBlockNode = "iterationStartBlockNode",
  IterationExitBlockNode = "iterationExitBlockNode"
}

export enum UtlBlockNode {
  ParentBlockNode = "parentBlockNode",
  InputBlockNode = "inputBlockNode",
  OutputBlockNode = "outputBlockNode"
}

export type AllBlockNodeTypes = BlockNode | UtlBlockNode;

export type TTextGenerationBlockType = BlockType.ChatGPT | BlockType.Anthropic;
