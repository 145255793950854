import {
  BlockNode,
  type StructuredBlockDataV2,
  type StructuredBlockProps,
  type UserInputDictType
} from "@toolflow/shared";
import { PromptTypeToOutputFieldMap } from "../../../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";
import DrawerBottom from "../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../../common/TestToolRun";
import TestBlockOutput from "../../components/TestBlockOutput";
import useTest from "../../hooks/useTest";

const TestStructuredBase = ({
  data,
  id,
  userInput,
  fields,
  testFields
}: Omit<StructuredBlockProps, "selected"> & {
  userInput: UserInputDictType;
  fields: string[];
  testFields: React.ReactNode;
}) => {
  const { type } = data;
  const { action, text, endIcon, showUpgrade, label, loading, output } =
    useTest<StructuredBlockDataV2>({
      data,
      id,
      fields,
      userInput,
      blockType: BlockNode.StructuredBlockNode
    });

  return (
    <DrawerBottom
      mainContent={
        <>
          {testFields}
          <TestBlockOutput
            output={output}
            label={label}
            type={PromptTypeToOutputFieldMap[type]}
          />
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
};

export default TestStructuredBase;
