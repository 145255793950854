import { FormControl, MenuItem, Select } from "@mui/material";
import {
  type DynamicSelectFieldConfig,
  type DynamicSelectFieldType
} from "@toolflow/shared";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import DynamicFieldWrapper from "../../dynamicFieldWrapper/DynamicFieldWrapper";
import { isString } from "lodash";

export default function DynamicSelectField() {
  const { field, id, data, updateField } =
    useFieldsByTypeContext<DynamicSelectFieldType>();
  const { config, label, subtitle } = field;
  const {
    fieldKey,
    typeKey,
    options,
    defaultValue = "",
    filterAvailableFields
  } = config as DynamicSelectFieldConfig;
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });

  const dynamicFieldValue = getFieldValue(typeKey, "");
  const fieldValue = getFieldValue(fieldKey, defaultValue);

  return (
    <FormControl margin="normal" fullWidth>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={config}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        onTypeChangeCallback={() => {
          updateField(defaultValue, fieldKey);
        }}
      >
        <div className="nowheel nodrag nopan m-t-16px">
          <Select
            fullWidth
            size="small"
            value={fieldValue}
            onChange={(event) => updateField(event.target.value, fieldKey)}
          >
            {options.map((option) => {
              if (isString(option)) {
                return (
                  <MenuItem value={option} key={option} className="capitalize">
                    {option}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem
                    value={option.value}
                    key={option.value}
                    className="capitalize"
                  >
                    {option.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </div>
      </DynamicFieldWrapper>
    </FormControl>
  );
}
