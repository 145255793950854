import { MenuItem, Select, Typography } from "@mui/material";
import {
  DynamicFieldType,
  type DynamicListFieldConfig,
  type SelectOption,
  type ValidatedInput
} from "@toolflow/shared";
import { isString } from "lodash";
import { ReactNode, useMemo } from "react";
import { ArrowDownSmallIcon } from "../../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";
import useUpdateField from "../../../../useUpdateField";
import styles from "./dynamicFieldWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export default function DynamicFieldWrapper({
  id,
  label,
  subtitle,
  fieldValue,
  type = DynamicFieldType.Dynamic,
  configs,
  children,
  filterOptions,
  onTypeChangeCallback
}: {
  id: string;
  label: string;
  subtitle?: string;
  fieldValue: string;
  type: DynamicFieldType;
  configs: DynamicListFieldConfig;
  children: ReactNode;
  onTypeChangeCallback?(type: DynamicFieldType): void;
  filterOptions?(options: ValidatedInput[]): ValidatedInput[];
}) {
  const { fieldKey, typeKey } = configs;
  const availableFields = useToolInputFieldsAndAvailableFields(id);
  const updateField = useUpdateField(id);

  const selectOptions: SelectOption[] = useMemo(() => {
    const fields = filterOptions?.(availableFields) || availableFields;
    return fields.map((field) => {
      return {
        label: field.config?.label || field.name,
        value: field.config?.label || field.name
      };
    });
  }, [filterOptions, availableFields]);

  return (
    <div className={cx("p-16px border-radius-8px", "container")}>
      <Typography variant="subtitle1" fontWeight="500">
        {label}
      </Typography>
      {subtitle && (
        <Typography variant="caption" color="text.secondary">
          {subtitle}
        </Typography>
      )}

      <Select
        value={type}
        size="small"
        fullWidth
        onChange={(event) => {
          updateField(event.target.value, typeKey);
          onTypeChangeCallback?.(event.target.value as DynamicFieldType);
        }}
        className={cx("select-field")}
        variant="standard"
        IconComponent={() => (
          <ArrowDownSmallIcon className={cx("select-field-icon")} />
        )}
      >
        <MenuItem
          className={cx("select-field-item")}
          value={DynamicFieldType.Dynamic}
          key={DynamicFieldType.Dynamic}
        >
          🎛️ Dynamic value
        </MenuItem>
        <MenuItem
          className={cx("select-field-item")}
          value={DynamicFieldType.Preset}
          key={DynamicFieldType.Preset}
        >
          🔒 Pre-set value
        </MenuItem>
      </Select>

      {type === DynamicFieldType.Dynamic ? (
        <Select
          value={fieldValue}
          size="small"
          fullWidth
          onChange={(event) => updateField(event.target.value, fieldKey)}
          className="capitalize nowheel nodrag nopan m-t-16px"
        >
          {selectOptions.map((option) => {
            if (isString(option)) {
              return (
                <MenuItem value={option} key={option} className="capitalize">
                  {option}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  value={option.value}
                  key={option.value}
                  className="capitalize"
                >
                  {option.label}
                </MenuItem>
              );
            }
          })}
        </Select>
      ) : (
        children
      )}
    </div>
  );
}
