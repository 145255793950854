import { useSelector } from "react-redux";
import { selectAllMarketplaceEntities } from "../../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import {
  useGetMarketplaceAgentsQuery,
  useGetMarketplaceEntitiesQuery
} from "../../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";
import {
  selectAllAgents,
  selectAllMarketplaceAgents
} from "../../../../ToolflowAPI/rtkRoutes/selectors/agentSelectors";
import { useGetAgentsQuery } from "../../../../ToolflowAPI/rtkRoutes/agentApi";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { EFeatureFlags } from "@toolflow/shared";

export const useAllMarketplaceEntitiesWithLoading = () => {
  const { isLoading } = useAuthStore();
  return useGetMarketplaceEntitiesQuery(undefined, {
    skip: isLoading
  });
};

export const useAllMarketplaceEntities = () => {
  useAllMarketplaceEntitiesWithLoading(); // needed to subscribe to the query
  return useSelector(selectAllMarketplaceEntities);
};

export const useAllMarketplaceAgentEntitiesWithLoading = () => {
  const { isLoading } = useAuthStore();
  const addAgentEnabled = useFeatureFlagEnabled(EFeatureFlags.AGENT_BUILDER);
  return useGetMarketplaceAgentsQuery(undefined, {
    skip: isLoading || !addAgentEnabled
  });
};

export const useAllMarketplaceAgentEntities = () => {
  useAllMarketplaceAgentEntitiesWithLoading(); // needed to subscribe to the query
  return useSelector(selectAllMarketplaceAgents);
};

export const useAllAgentEntitiesWithLoading = () => {
  const { isLoading } = useAuthStore();
  return useGetAgentsQuery(undefined, {
    skip: isLoading
  });
};

export const useAllAgentEntities = () => {
  useAllAgentEntitiesWithLoading(); // needed to subscribe to the query
  return useSelector(selectAllAgents);
};
