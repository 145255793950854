import { type FieldsByType, FieldType } from "@toolflow/shared";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";
import { perplexityInitialStateV2 } from "../../../../context/initialStates/perplexityInitialState";
import { getPerplexityModelOptions } from "../../../../helpers/priceDict";

export default function usePerplexityFieldConfigs(id: string): FieldsByType {
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  return [
    {
      type: FieldType.PromptInputV2,
      label: "Prompt",
      subtitle: "Set and preview AI instructions for response generation.",
      config: {
        fieldKey: "settings.input",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        className: "min-h-246px",
        chipsToUse
      }
    },
    {
      type: FieldType.Group,
      label: "LLM Settings",
      subtitle: "Select LLM settings for this block.",
      fields: [
        {
          type: FieldType.DynamicSelectField,
          label: "Model",
          subtitle: "Select text generation technology",
          config: {
            typeKey: "optimizations.llmModel.type",
            fieldKey: "optimizations.llmModel.value",
            options: getPerplexityModelOptions(),
            defaultValue: perplexityInitialStateV2.optimizations.llmModel.value
          }
        },
        {
          type: FieldType.DynamicSliderField,
          label: "Temperature",
          subtitle:
            "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
          config: {
            typeKey: "optimizations.temperature.type",
            fieldKey: "optimizations.temperature.value",
            defaultValue:
              perplexityInitialStateV2.optimizations.temperature.value
          }
        }
      ]
    }
  ];
}
