import { Box, Stack } from "@mui/material";
import { FieldType, type FieldsByTypeProps } from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import styles from "./fieldsByType.module.css";
import { FieldsByTypeContextComponent } from "./FieldsByTypeContext";
import GroupedFieldWrapper from "./fieldsGroupComponent/GroupedFieldWrapper";
import { fieldTypeComponents } from "./fieldTypeComponents";

const cx = parsedClassNames.bind(styles);

export default function FieldsByType({ configs }: FieldsByTypeProps) {
  return (
    <Stack spacing={2} /* divider={<Divider />} */>
      {configs.fields.map((field, index) => {
        const { data, id, updateField } = configs;

        if (field.type === FieldType.Group) {
          return (
            <GroupedFieldWrapper key={index} configs={configs} group={field} />
          );
        }

        const Component = fieldTypeComponents[field.type];
        if (!Component) return null;
        return (
          <Box key={index} className={cx("box-margin-inline")}>
            <FieldsByTypeContextComponent
              configs={{ data, field, id, updateField }}
            >
              <Component field={field} settings={data} id={id} />
            </FieldsByTypeContextComponent>
          </Box>
        );
      })}
    </Stack>
  );
}
