import {
  BlockType,
  UtilBlockType,
  type BlockDataTypesWithDefault,
  type DeepgramBlockSettings,
  type ScraperBlockSettingsV1,
  type ScraperBlockSettingsV2
} from "@toolflow/shared";
import type { Edge } from "reactflow";

const getDependencyArray = (
  data: BlockDataTypesWithDefault,
  edges: Edge[],
  id?: string
) => {
  const { type } = data;
  if (type === UtilBlockType.Constant) {
    return [data.constant, id];
  }
  if (type === BlockType.Deepgram) {
    return [(data.settings as DeepgramBlockSettings).file, id];
  }
  if (type === BlockType.Scraper) {
    const url =
      (data.settings as ScraperBlockSettingsV1).urlFieldInputKey ||
      (data.settings as ScraperBlockSettingsV2).url; // Hacky way but it works
    return [url, id];
  }
  if (type === UtilBlockType.ToolWithinTool) {
    return [data.inputMap, id];
  }
  if (type === UtilBlockType.Logic) {
    return [JSON.stringify(data.logicArray), JSON.stringify(edges), id];
  }
  return [id];
};

export default getDependencyArray;
