import {
  BlockType,
  DEFAULT_VERSION_VALUE,
  DynamicFieldType,
  InputType,
  UtilBlockType,
  VERSION_2_VALUE,
  deepCopy,
  returnNewInputMap,
  updateDeepContentName,
  updateNodeName,
  updateStringWithNewLabel,
  type BlockInputUpdater,
  type DecimalString,
  type LogicItem,
  type SerpBlockDataV1,
  type TBlock
} from "@toolflow/shared";
import {
  get as getValueFromPath,
  isArray,
  isString,
  isUndefined,
  set as setValueFromPath
} from "lodash";

type BlockTypeWithUpdater =
  | BlockType.ChatGPT
  | BlockType.DallE
  | BlockType.Deepgram
  | BlockType.Scraper
  | BlockType.Structured
  | BlockType.Perplexity
  | BlockType.SERP
  | BlockType.IterationStart
  | UtilBlockType.Logic
  | UtilBlockType.Constant
  | UtilBlockType.ToolWithinTool;

function updateStringWithinBrackets(
  block: TBlock,
  path: string,
  oldName: string,
  newName?: string,
  deleteBrackets?: boolean
) {
  const data = deepCopy(block.data);
  const oldValue = getValueFromPath(data, path);
  if (oldValue) {
    const newValue = updateStringWithNewLabel({
      newName,
      deleteBrackets,
      deletedToolInputFieldName: oldName,
      stringToUpdate: oldValue
    });
    setValueFromPath(data, path, newValue);
  }
  return { ...block, data };
}

function updateJsonContent(
  block: TBlock,
  path: string,
  oldName: string,
  newName?: string
) {
  const data = deepCopy(block.data);
  const oldValue = getValueFromPath(data, path);
  if (oldValue) {
    const newValue = updateDeepContentName(oldValue, oldName, newName);
    setValueFromPath(data, path, newValue);
  }

  return { ...block, data };
}

export const blockInputUpdater: BlockInputUpdater<BlockTypeWithUpdater> = {
  [BlockType.ChatGPT]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "prompt",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "settings.prompt", oldName, newName)
  },
  [BlockType.DallE]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "prompt",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "settings.prompt", oldName, newName)
  },
  [BlockType.Deepgram]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      if (oldName !== "") {
        const data = deepCopy(block.data);
        if (data.settings.file === oldName) {
          data.settings.file = newName;
        } else if (data.settings.userKeywordsFieldKey === oldName) {
          data.settings.userKeywordsFieldKey = newName;
        } else if (data.settings.userWordsToReplaceFieldKey === oldName) {
          data.settings.userWordsToReplaceFieldKey = newName;
        }
        return { ...block, data };
      }
      return block;
    }
  },
  [BlockType.Scraper]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "settings.urlFieldInputKey",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "settings.urlFieldInputKey", oldName, newName)
  },
  [BlockType.SERP]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      const data = deepCopy(block.data) as SerpBlockDataV1;
      const queries = data.settings.queries;
      if (
        data.settings.inputType === InputType.BlockInput &&
        isArray(queries)
      ) {
        if (isString(newName)) {
          data.settings.queries = queries.map((query) => {
            if (query === oldName) return newName;
            return query;
          });
        } else {
          data.settings.queries = queries.filter((query) => query !== oldName);
        }
      }

      return { ...block, data };
    },
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "settings.urlFieldInputKey", oldName, newName)
  },
  [BlockType.Perplexity]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "settings.input",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) => {
      const updatedBlock: TBlock = updateJsonContent(
        block,
        "settings.input",
        oldName,
        newName
      );

      Object.keys(updatedBlock.data.optimizations).forEach((key) => {
        if (
          updatedBlock.data.optimizations &&
          updatedBlock.data.optimizations[key] &&
          updatedBlock.data.optimizations[key].type === DynamicFieldType.Dynamic
        ) {
          const path = `data.optimizations.${key}.value`;
          const stringToUpdate = getValueFromPath(updatedBlock, path);
          if (isString(stringToUpdate)) {
            const newValue = updateNodeName({
              oldName,
              newName,
              stringToUpdate
            });
            setValueFromPath(updatedBlock, path, newValue);
          }
        }
      });

      return updatedBlock;
    }
  },
  [BlockType.Structured]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "settings.input",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "settings.input", oldName, newName)
  },
  [BlockType.IterationStart]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "settings.input",
        oldName,
        newName,
        deleteBrackets
      )
  },
  [UtilBlockType.Logic]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName) => {
      if (isUndefined(newName)) {
        return {
          ...block,
          data: {
            ...block.data,
            logicArray: block.data.logicArray.filter(
              (logic: LogicItem) => logic.input !== oldName
            )
          }
        };
      } else {
        return {
          ...block,
          data: {
            ...block.data,
            logicArray: block.data.logicArray.map((logic: LogicItem) =>
              logic.input === oldName ? { ...logic, input: newName } : logic
            )
          }
        };
      }
    }
  },
  [UtilBlockType.Constant]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName = "") => {
      const newInputMap = returnNewInputMap(
        block.data.inputMap,
        oldName,
        newName
      );
      return {
        ...block,
        data: {
          ...block.data,
          inputMap: newInputMap
        }
      };
    },
    [VERSION_2_VALUE]: (block, oldName, newName) => {
      return updateJsonContent(block, "constant", oldName, newName);
    }
  },
  [UtilBlockType.ToolWithinTool]: {
    [DEFAULT_VERSION_VALUE]: (block, oldName, newName, deleteBrackets) =>
      updateStringWithinBrackets(
        block,
        "constant",
        oldName,
        newName,
        deleteBrackets
      ),
    [VERSION_2_VALUE]: (block, oldName, newName) =>
      updateJsonContent(block, "constant", oldName, newName)
  }
};

export function getBlockInputUpdaterFunction(
  blockType: BlockTypeWithUpdater,
  version: DecimalString
) {
  return (
    blockInputUpdater[blockType]?.[version] ||
    blockInputUpdater[blockType]?.[DEFAULT_VERSION_VALUE]
  );
}
