import {
  DynamicFieldType,
  FieldType,
  type BlockDataBase,
  type FieldsByType,
  type FieldTypeConfigs,
  type UserInputDictType
} from "@toolflow/shared";
import { isEmpty, isString } from "lodash";
import { useEffect, useMemo, useState } from "react";
import useFieldsByTypeHelper from "../../common/fieldsByType/useFieldsByTypeHelper";

export default function useTestInputFromFieldsByType(
  fieldsByType: FieldsByType,
  data: BlockDataBase,
  deps?: $TSAllowedAny[] // Need this because using data as deps will not work because memo doesn't do deep compare
) {
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });

  const [userInput, setUserInput] = useState<UserInputDictType>({});

  function getFields(acc: Set<string>, field: FieldTypeConfigs) {
    if (field.type.startsWith("dynamic-") && "typeKey" in field.config) {
      const type = getFieldValue(field.config.typeKey, "") as DynamicFieldType;
      if (type === DynamicFieldType.Dynamic) {
        const value = getFieldValue(field.config.fieldKey, null);
        if (isString(value) && !isEmpty(value)) {
          acc.add(value);
        }
      }
    }
  }

  // TODO: Could improve by not creating fields at all
  // instead, just create userInputs and its keys array can be used as fields
  const fields = useMemo(
    () => {
      return [
        ...fieldsByType.reduce((acc, field) => {
          if (field.type === FieldType.Group) {
            field.fields.forEach((f) => getFields(acc, f));
          } else {
            getFields(acc, field);
          }
          return acc;
        }, new Set<string>()) // Using Set to keep entries uniq
      ];
    },
    deps || [data]
  );

  useEffect(() => {
    setUserInput((prev) => {
      return [...fields].reduce(
        (acc, field) => {
          if (!prev.hasOwnProperty(field)) {
            acc[field] = "";
          }
          return acc;
        },
        { ...prev }
      );
    });
  }, [fields]);

  return { userInput, setUserInput, fields };
}
